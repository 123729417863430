@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face {
    font-family: 'Azonix Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Azonix Regular'), url('../../fonts/Azonix.woff') format('woff');
    }

:root{
    --themeclrs: #a04e80;
    --themebackclr: #0a0a0a;
}

/* new css */
.airdrop{
    border: 1px solid #80808059;
    border-radius: 10px;
    padding: 30px 0px;
    background: #000000ad;
}
.pname{
    font-size: 16px;
    font-weight: 500;
}
.imgpos{
    position: relative;
    width: 100%;
    max-width: 250px;
    margin: auto;
    background: #21252929;
    border: 1px solid #813f6726;
    border-radius: 10px;
}
.sitepos{
    position: absolute;
    left: 30px;
    top: 8px;
    width: 26px;
    height: 26px;
}
.form-check-input.sitepos:checked, .form-check-input.sitepos:focus{
    background-color: var(--themeclrs);
    border-color: var(--themeclrs);
    box-shadow: unset !important;
}
div:where(.swal2-container) div:where(.swal2-popup) {
    background: #000 !important;
    color: #fff !important;
    border: 1px solid #80808040 !important;
}

.cname{font-family: 'Azonix Regular' !important;
    font-size: 22px !important;}
.nftsimg{
    width: 250px;
    /* height: 250px; */
    max-width: 100%;
    object-fit: cover;
    border-radius: 10px;

}
.dropdown .dropsets.dropdown-menu {
    height: 200px;
    overflow-y: auto;
}
.rewardss .hcontrol{
    height: 36px !important;
}
.liststyle{
    list-style-type: none;
    height: 250px;
    overflow-y: auto;
    padding-right: 10px;
}
.liststyle::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.liststyle::-webkit-scrollbar
{
	width: 8px;
	/* background-color: #F5F5F5; */
}

.liststyle::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #a04e80;
}
.table-striped>tbody>tr:nth-of-type(odd)>* {
color: #fff !important;
}
.table>:not(:first-child) {
    border-top: 2px solid transparent !important;
}
.sidebar-icon-only .accordion{display: none;}
.accordion-item {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, .125);
}
.accordion-button{background-color: transparent;color: #fff;}
.accordion-button:focus{
    border-color: transparent !important;
    box-shadow: unset !important;
}
.accordion-button:not(.collapsed) {
    color: var(--themeclrs) !important;
    background-color: transparent;
    box-shadow: unset !important;
}
.accordion-button::after{
    content: "";
    background: url("../images/whitearrow.png") no-repeat !important;
    margin-top: 8px;
    /* background-size: 1.25rem !important;
    width: 1.25rem !important;
    height: 1.25rem !important; */
}
.accordion-button:not(.collapsed)::after{
    content: "";
    background: url("../images/themearrow.png") no-repeat !important;
    margin-top: -10px;
}
.pdflink{
    color: var(--themeclrs);
    font-weight: 600;
    text-decoration: underline;
}
.buildcard .dropdown .dropdown-menu::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: transparent;
}

.buildcard .dropdown .dropdown-menu::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}

.buildcard .dropdown .dropdown-menu::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--themeclrs);
}
.buildcard .dropdown .dropdown-menu{
    height: 250px;
    overflow: auto;
}
.modal-content .modal-header .close{
    color: #fff !important;
}
.fileimg{
    width: 60px;
    height: 60px;
    object-fit: contain;
}
.upimg{
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}
.browseinput input{
    opacity: 0;
}
.browseinput{
    position: relative;
    z-index: 3;
}
.rewardbtn{
    height: 48px;
    background: #d9d9d90a !important;
    border: 1px solid #2c2e33 !important;
}
.rewardbtn::after{
    position: absolute;
    right: 10px;
    top: 45%;
}
.card .card-title{
    font-family: 'Azonix Regular' !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #a04e8040;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
/* body{
    font-family: "Inter", sans-serif !important;
} */
.stars{
    overflow: hidden;
}
.stars article{
    position: absolute;
    top:50%;
    left:50%;
    width: 4px;
    height: 4px;
    background: var(--whiteclr);
    border-radius: 50%;
    box-shadow: 0 0 0 4px rgba(255,255,255,0.1),0 0 0 8px rgba(255,255,255,0.1),0 0 20px rgba(255,255,255,0.1);
    animation: animate 5s linear infinite;
  }
  .stars article::before{
    content:'';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 1px;
    background: linear-gradient(90deg,#fff,transparent);
  }
  @keyframes animate
{
  0%
  {
      transform: rotate(315deg) translateX(0);
      opacity: 1;
  }
  70%
  {
      opacity: 1;
  }
  100%
  {
      transform: rotate(315deg) translateX(-1000px);
      opacity: 0;
  }
}
.stars article:nth-child(1){
  top: 0;
  right: 0;
  left: initial;
  animation-delay: 0s;
  animation-duration: 1s;
}
.stars article:nth-child(2){
  top: 0;
  right: 80px;
  left: initial;
  animation-delay: 0.2s;
  animation-duration: 3s;
}
.stars article:nth-child(3){
  top: 80;
  right: 0px;
  left: initial;
  animation-delay: 0.4s;
  animation-duration: 2s;
}
.stars article:nth-child(4){
  top: 0;
  right: 180px;
  left: initial;
  animation-delay: 0.6s;
  animation-duration: 1.5s;
}
.stars article:nth-child(5){
  top: 0;
  right: 400px;
  left: initial;
  animation-delay: 0.8s;
  animation-duration: 2.5s;
}
.content-wrapper{
    background: url("../images/galaxyback.png") no-repeat !important;
    background-size: 100% 100% !important;
    width: 100%;
}
.content-wrapper::after{
    background: #a24b8070;
    content: "";
    filter: blur(100px);
    height: 100px;
    left: 0;
    position: absolute;
    top: 20%;
    width: 100%;
    z-index: -1;
}
.redclr{
    color: red;
}
.sidebar .sidebar-brand-wrapper, .sidebar, .navbar .navbar-brand-wrapper, .navbar{
    /* background: linear-gradient(81.33deg,#1f2421 7.09%,#546158 173.02%) !important; */
    background: var(--themebackclr);
}
.sidebar .nav .nav-item.nav-category .nav-link, .sidebar .nav .nav-item .nav-link, .sidebar .nav.sub-menu .nav-item .nav-link, .navbar .navbar-menu-wrapper .navbar-toggler{
    color: #fff;
    font-weight: 500;
}
.sidebar .nav:not(.sub-menu) > .nav-item:hover:not(.nav-category):not(.account-dropdown) > .nav-link{
    background: #fff !important;
}
.sidebar .nav:not(.sub-menu) > .nav-item:hover:not(.nav-category):not(.account-dropdown) > .nav-link .menu-title{
    color: #000;
}
.sidebar .nav .menu-items:nth-child(5n+3) .nav-link .menu-icon i{
    color: #8bed0f;
}
.card{
    background: var(--themebackclr);
    border: 1px solid #181818;
}
.table, .text-muted, th.sortable{
    color: #fff !important;
}
.footer a{
    color: var(--themeclrs) !important;
}
.form-control::placeholder{
    color: #ffffffbd !important;
}
.form-control{
    background: #d9d9d90a !important;
    height: 48px !important;
}
.form-control:disabled, .form-control[readonly] {
    color: #c7c7c7 !important;
}
.sidebar .nav.sub-menu .nav-item .nav-link:hover {
    color: var(--themeclrs);
}
.sidebar .sidebar-brand-wrapper .sidebar-brand.brand-logo-mini img
{
    max-width: 45px;
    margin-top: 4px !important;
}
select option {
    /* background: #000 !important; */
}
.icon.icon-box-success {
    color: #00d25b !important;
    /* background: #00d25b !important; */
}
.auth .brand-logo {
    text-align: center;
}
/* new end */










.sidebar .sidebar-brand-wrapper .sidebar-brand.brand-logo-mini img {
    width: calc(90px - 50px);
}

.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
    width: calc(90px - 50px);
}

.sidebar .sidebar-brand-wrapper .sidebar-brand img{
    width: calc(244px - 100px) !important;
    margin-top: 16px !important;
}
.userlistss tbody tr td{
   padding: 12px 12px;
}
.form-control:focus {
    color: #fff !important;
    border: 1px solid #a04e8040 !important;
}
/* .asrt-page-length select{
    background: transparent !important;
    border: 1px solid #413e47 !important;
} */
select {
    cursor: pointer;
    background: #d9d9d90a !important;
    border: 1px solid #2c2e33 !important;
    color: var(--whiteclr);
}

select option {
    cursor: pointer;
    color: fff;
    background-color: #000;
}

input[type="search"]::-webkit-search-cancel-button {
    cursor: pointer !important;
    color: #fff !important;
}

.asrt-pagination li {
    cursor: pointer;
}

.passwordinput .form-control {
    background-color: #191c24;
}
.passwordinput .btn-outline-primary {
    color: var(--themeclrs);
    border-color: #494a51;
    padding: 10px 10px;
    height: 48px;
}
.passwordinput .btn-outline-primary:hover {
    background-color: transparent !important;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: unset !important;
}
/* .footer a{
    color: #886abe !important;
} */
.asrt-page-length .input-group-addon{
    background: unset !important;
}
.asrt-page-length select{
    color: #fff !important;
}
.input-group-prepend span{
    color: #fff !important;
}
.asrt-table-foot{
    margin-top: 2%;
}
.page-item.disabled .page-link {
    color: #fff !important;
    pointer-events: none;
    cursor: auto;
    background-color: #fff0 !important;
    border-color: #fff !important;
}
.page-item.disabled .page-link:hover{
    background-color: var(--themeclrs) !important;
    color: #fff !important;
    border-color: var(--themeclrs) !important;
    border: 1px solid var(--themeclrs);
}
.as-react-table .pagination li:hover a {
    background-color: var(--themeclrs) !important;
    color: #fff !important;
}
.page-link {
    background-color: #00000000 !important;
    border: 1px solid #fff;
}
.page-link input{
    background: unset !important;
    color: #fff !important;
}
.allbtn{
    background: var(--themeclrs);
    border: 1px solid var(--themeclrs);
    font-weight: 500;
    color: #fff;
    padding: 10px 16px;
}
.table thead th{
   line-height: 21px;
}
.allbtnlogout{
    background: red;
    border: 1px solid red;
    padding: 6px 14px;
    color: #fff;
    border-radius: 6px;
}
.allbtnlogout:hover{
    background: transparent;
    border: 1px solid red;
    color: red;
}
.allbtns{
    padding: 8px 16px !important; 
}
.css-yt9ioa-option {
    color: black !important; 
    /* background-color: #886abe !important; */
}
.css-1n7v3ny-option {
    /* background-color: #886abe !important; */
}

.NFT.IDT{
    width: 580px !important;
    max-width: 580px !important;
    word-wrap: break-word !important;
}
.NFT.IDT p{
    line-height: normal;
    font-weight: 400;
}

.css-6c09z3-option{
    color:#000 !important;
}
.css-1gj9k8-option{
    background-color: unset !important;
    color: #000 !important;
}
.css-zjsb6u-option{
    background-color: var(--themeclrs) !important;
}

.css-1n7v3ny-option {
    background-color: unset !important;
    color: black !important;
   /* background-color: transparent !important;
   color: black !important; */
}
.css-1pahdxg-control:hover{
    border-color: white !important;
}
.css-9gakcf-option {
    background-color: var(--themeclrs) !important;
}
/* .css-9gakcf-option {
    background-color: transparent !important;
    color: black !important;
} */

.allbtn:hover{
    background: transparent;
    border: 1px solid var(--themeclrs);
    color: var(--themeclrs) !important;
}
.icon.icon-box-success {
    background: #a04e8057 !important;
}
.icon .icon-item {
    color: #fff;
}
.grid-margin .card .card-body h3, .proBanner .purchase-popup .card .card-body h3 {
    word-wrap: break-word;
    word-break: break-word;
}
.ReactModal__Overlay {
    background-color: #00000091 !important;
}
.Toastify__toast-theme--light{
    border: 1px solid #100f0f !important;
    background: linear-gradient(180deg, #a04e80, #17161a 77%, #141417) !important;
}
.Toastify__toast-body{
    color:#fff !important;
}
.Toastify__close-button{
    opacity: 1 !important;
}
.Toastify__close-button svg{
  fill: #fff !important;
}
.ReactModal__Content{
    border: 1px solid #100f0f !important;
    background: linear-gradient(180deg, #a04e80, #17161a 77%, #141417) !important;
    border-radius: 16px !important;
}
.navbar .navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.navbar-dropdown{
    background: var(--themebackclr);
    box-shadow: 0px 0px 35px -3px #a94a7e36 !important;
}
.ReactModal__Content h3{
    color: #fff !important;
    font-family: 'Azonix Regular' !important;
}
.closebtn{
    position: absolute;
    right: 8px;
    top: 4px;
    font-size: 19px;
    font-weight: 500;
}
.closebtn:hover{
   color: var(--themeclrs);
}
.table-responsive{
    padding-bottom: 2%;
    overflow-x: auto !important;
}
/* .asrt-table-body {
    overflow: auto;
    padding-bottom: 2%;
} */
.table-responsive::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.table-responsive::-webkit-scrollbar
{
	width: 8px !important;
    height: 9px !important;
	background-color: #F5F5F5;
}

.table-responsive::-webkit-scrollbar-thumb
{
    border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--themeclrs);
}
.faq .as-react-table{
    padding-bottom: 2%;
    overflow-x: auto !important;
}
.faq .as-react-table::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.faq .as-react-table::-webkit-scrollbar
{
	width: 8px !important;
    height: 9px !important;
	background-color: #F5F5F5;
}

.faq .as-react-table::-webkit-scrollbar-thumb
{
    border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--themeclrs);
}
.sidebar .nav .nav-item .nav-link i.menu-arrow:before {
  display: none;
}
.dash-token{
    color: var(--themeclrs) !important;
}
.editservice{
    font-size: 15px;
}
.sidebar .nav.sub-menu .nav-item .nav-link {
    white-space: normal;
    line-height: 20px;
}
/* th.sortable {
    color: #886abe !important;
    font-weight: 600 !important;
} */

@media only screen and (min-width: 1500px) {
.col-xxl-3{
    flex: 0 0 25%;
    max-width: 25%;
}
}

@media only screen and (max-width: 767px) {
     .table-responsive::-webkit-scrollbar
{
	width: 6px !important;
    height: 6px !important;
	background-color: #F5F5F5;
}
.faq .as-react-table::-webkit-scrollbar
{
	width: 6px !important;
    height: 6px !important;
	background-color: #F5F5F5;
}
    .table-responsive{
        padding-bottom: 8%;
    }
    .faq .as-react-table{
        padding-bottom: 8%;
    }
    .pagination {
        justify-content: unset !important;
    }
    .pagination li{
        font-size: 11px !important;
        margin-top: 10px;
    }
    .table_filter input{
       margin-top: 14px;
    }
   .asrt-page-length .input-group-text{
       font-size: 11px;
    }
    .asrt-page-length select.form-control {
        padding: 0.4375rem 0.45rem !important;
        width: 53px !important;
        font-size: 12px !important;
    }
    .asrt-table-head .text-right{
        text-align: left !important;
    }
    .asrt-table-foot>div:first-child {
       font-size: 12px;
       margin-top: 6px;
    }
  }
  .navbar.loginnavbar{
    left: 0px !important;
}
.btn-primary:not(.btn-light):not(.btn-secondary):focus, .custom-file .custom-file-label:not(.btn-light):not(.btn-secondary):focus:after, .btn-primary:not(.btn-light):not(.btn-secondary):active, .custom-file .custom-file-label:not(.btn-light):not(.btn-secondary):active:after {
    background: var(--themeclrs) !important;
    border-color: var(--themeclrs) !important;
    color: #fff !important;
}

.referralpages .btnsec{
    display: flex;
    align-items: center;
    margin-top: 8px;
}
.referralpages .add_btnsec{
    margin-left: 15px;
    margin-top: 10px;
}
.referralpages .allbtn{
    width: 100px;
}

@media only screen and (max-width: 767px) {
    .referralpages .add_btnsec{
        margin-left:12px;
    }
    .referralpages .btnsec{
        margin-bottom: 15px;
    }
}


/* new css */

.imginput {
    background-color: transparent !important;
    border: 0px;
    padding-left: 0;
}

.stretch-card .recardtable {
    margin-left: -12px;
    margin-right: -12px;
}


/* new css */
